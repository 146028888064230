import { makeAutoObservable } from 'mobx';

class TaskStore {
  selectors = new Map();
  schema: any = {
    tests: [],
    experiments: [],
    cultures: [],
    mixtures: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  generateNum() {
    let newArr = [];

    for (let j = 0; j < this?.schema.tests?.length; j++) {
      for (let i = 0; i < this?.schema.numRepeats; i++) {
        newArr.push({
          name: `№${i + 1}-${this?.schema.tests[j]?.name}`,
          dosage: `${this?.schema.tests[j]?.dosage}`,
          id: null,
        });
      }
    }
    console.log(newArr);
    return newArr;
  }
  update(key: string, val: string | object) {
    this.schema[key] = val;
  }
  setVariables(val: any) {
    this.schema = val;
  }
  resetVariables() {
    this.schema = {
      tests: [],
      experiments: [],
      cultures: [],
      mixtures: [],
    };
  }
}

const taskStore = new TaskStore();
export default taskStore;
