import RestClient from '../restClient';

class ImageEntityController {
  static postImage(data: any, id: number) {
    return RestClient.postAxiosBlob(`/images/upload`, data, {
      Taskid: id,
    });
  }
  static getAllImagesByTaskId(id: number) {
    return RestClient.getAxios(`/images/get-all-by-task-id?testId=${id}`);
  }
  static getImage(id: number) {
    return RestClient.getBlob(`/images/download?testId=${id}`);
  }

  static getFile(id: number) {
    return RestClient.getBlobSap(
      `${process.env.REACT_APP_REPORT_URL || 'http://188.120.235.15:3746'}/?task_id=${id}`,
    );
  }
  static deleteImage(id: number) {
    return RestClient.deleteAxios(`/images/${id}`);
  }
}
export default ImageEntityController;
