import RestClient from '../restClient';

class CultureTypeEntityController {
  static postCultureTypes(data: any) {
    return RestClient.postAxios(`/culture-types/search`, data);
  }
  static postCultureType(data: any) {
    return RestClient.postAxios(`/culture-types`, data);
  }

  static getCultureType(id: number) {
    return RestClient.getAxios(`/culture-types/${id}`);
  }
  static deleteCultureType(id: number) {
    return RestClient.deleteAxios(`/culture-types/${id}`);
  }
}
export default CultureTypeEntityController;
