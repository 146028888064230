import { lazy } from 'react';

import { Route, Routes } from 'react-router-dom';

const ProfilePage = lazy(() => import('./profile'));
const TaskPage = lazy(() => import('./task'));
const ExperimentPage = lazy(() => import('./experiment'));
const MixturesPage = lazy(() => import('./mixtures'));
const PropertyPage = lazy(() => import('./property'));
const PracticePage = lazy(() => import('./practice'));
const MeasurePage = lazy(() => import('./measure'));
const ParametrPage = lazy(() => import('./parameters'));
const TypeCulturePage = lazy(() => import('./typeCulture'));
const CulturePage = lazy(() => import('./сulture'));
const StatusPage = lazy(() => import('./status'));
const GroundPage = lazy(() => import('./ground'));
const MainPage = lazy(() => import('./main'));
const WeatherPage = lazy(() => import('./weather'));
const CalendarPage = lazy(() => import('./calendar'));
export const Routing = ({ user }: any) => {
  return (
    <Routes>
      <Route
        errorElement={<div>что то пошло не так</div>}
        path='/calendar'
        Component={() => <CalendarPage user={user} />}
      />
      <Route
        errorElement={<div>что то пошло не так</div>}
        path='/'
        Component={() => <MainPage user={user} />}
      />
      <Route
        errorElement={<div>что то пошло не так</div>}
        path='/profile'
        Component={() => <ProfilePage user={user} />}
      />
      <Route
        path='/mixture/*'
        errorElement={<div>что то пошло не так</div>}
        Component={MixturesPage}
      />
      <Route
        path='/weather/*'
        errorElement={<div>что то пошло не так</div>}
        Component={WeatherPage}
      />
      <Route path='/task/*' errorElement={<div>что то пошло не так</div>} Component={TaskPage} />
      <Route
        path='/status/*'
        errorElement={<div>что то пошло не так</div>}
        Component={StatusPage}
      />
      <Route
        path='/ground/*'
        errorElement={<div>что то пошло не так</div>}
        Component={GroundPage}
      />
      <Route
        path='/typeCulture/*'
        errorElement={<div>что то пошло не так</div>}
        Component={TypeCulturePage}
      />
      <Route
        path='/culture/*'
        errorElement={<div>что то пошло не так</div>}
        Component={CulturePage}
      />
      <Route
        path='/experiment/*'
        errorElement={<div>что то пошло не так</div>}
        Component={ExperimentPage}
      />
      <Route
        path='/property/*'
        errorElement={<div>что то пошло не так</div>}
        Component={PropertyPage}
      />
      <Route
        path='/practice/*'
        errorElement={<div>что то пошло не так</div>}
        Component={PracticePage}
      />
      <Route
        path='/measure/*'
        errorElement={<div>что то пошло не так</div>}
        Component={MeasurePage}
      />
      <Route
        path='/parameter/*'
        errorElement={<div>что то пошло не так</div>}
        Component={ParametrPage}
      />
    </Routes>
  );
};
