import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import SvgLoader from 'shared/ui/loader';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://71f75ec3c71c3566e60695d7f3e10cac@o4506902723362816.ingest.us.sentry.io/4506904735776768',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
export const withRouter = (component: () => React.ReactNode) => () =>
  (
    <BrowserRouter>
      <Suspense fallback={<SvgLoader />}>{component()}</Suspense>
    </BrowserRouter>
  );
