import { ConfigProvider } from 'antd';
export const withAntdProvider = (component: () => React.ReactNode) => () =>
  (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#00b96b',
          borderRadius: 2,

          // Alias Token
          colorBgContainer: '#E3EDF5',
          colorText: '#0D2231',
        },
      }}>
      {component()}
    </ConfigProvider>
  );
