import RestClient from '../restClient';

class MixturesEntityController {
  static postMixtures(data: any) {
    return RestClient.postAxios(`/mixture/search`, data);
  }
  static postMixture(data: any) {
    return RestClient.postAxios(`/mixture`, data);
  }

  static getMixture(id: number) {
    return RestClient.getAxios(`/mixture/${id}`);
  }
  static deleteMixture(id: number) {
    return RestClient.deleteAxios(`/mixture/${id}`);
  }
}
export default MixturesEntityController;
