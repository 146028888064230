import RestClient from '../restClient';

class TestEntityController {
  static postTest(data: any) {
    return RestClient.postAxios(`/tests`, data);
  }
  static postTests(data: any) {
    return RestClient.postAxios(`/tests/search`, data);
  }

  static getTest(id: number) {
    return RestClient.getAxios(`/tests/${id}`);
  }
  static deleteTest(id: number) {
    return RestClient.deleteAxios(`/tests/${id}`);
  }
  static patchTest(id: number, data: any) {
    return RestClient.patchAxios(`/tests/${id}`, data);
  }
}
export default TestEntityController;
