import RestClient from '../restClient';

class WeatherEntityController {
  static postWeathers(data: any) {
    return RestClient.postAxios(`/weather/search`, data);
  }
  static postWeather(data: any) {
    return RestClient.postAxios(`/weather`, data);
  }

  static getWeather(id: number) {
    return RestClient.getAxios(`/weather/${id}`);
  }
  static deleteWeather(id: number) {
    return RestClient.deleteAxios(`/weather/${id}`);
  }
  static patchWeather(id: number, data: any) {
    return RestClient.patchAxios(`/Weathers/${id}`, data);
  }
}
export default WeatherEntityController;
