import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  User,
} from '@nextui-org/react';
// @ts-ignore
import img from 'shared/logo.svg';
// @ts-ignore
import { CalendarTwoTone, FundTwoTone } from '@ant-design/icons';
import keycloak from 'app/providers/providerConfig/Keycloak';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { message } from 'antd';
import userStore from 'entities/user/UserStore';
import FileSaver from 'file-saver';
import { observer } from 'mobx-react';
import ApiDiContainer from 'shared/apiService/apiDiContainer';
// @ts-ignore;
import avatarUrl from 'shared/avatar.png';
import { listOfRoles } from 'widgets/userTools';

const HeaderApp = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    { name: 'Задачи', id: 'task' },
    { name: 'Эксперименты', id: 'experiment' },
    { name: 'Опыты', id: 'practice' },
    { name: 'Параметры', id: 'parameter' },
    { name: 'Величины', id: 'measure' },
    { name: 'Земля', id: 'ground' },
    { name: 'Статусы', id: 'status' },
    { name: 'Культуры', id: 'culture' },
    { name: 'Типы культур', id: 'typeCulture' },
    { name: 'Условия', id: 'weather' },
    { name: 'Препараты', id: 'mixture' },
  ];
  return (
    <Navbar
      isBordered
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      classNames={{
        item: [
          'flex',
          'relative',
          'h-full',
          'items-center',
          "data-[active=true]:after:content-['']",
          'data-[active=true]:after:absolute',
          'data-[active=true]:after:bottom-0',
          'data-[active=true]:after:left-0',
          'data-[active=true]:after:right-0',
          'data-[active=true]:after:h-[2px]',
          'data-[active=true]:after:rounded-[2px]',
          'data-[active=true]:after:bg-primary',
        ],
      }}>
      <NavbarContent className='md:hidden' justify='start'>
        <NavbarMenuToggle aria-label={isMenuOpen ? 'Close menu' : 'Open menu'} />
      </NavbarContent>
      <NavbarMenu>
        {menuItems.map((item) => (
          <NavbarMenuItem key={item.id}>
            <Link className='w-full' color='foreground' href={item.id} size='lg'>
              {item.name}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
      <NavbarBrand className='cursor-pointer' onClick={() => navigate('/')}>
        <img src={img} alt='logo' />
        <p className='font-bold text-inherit textLogo  '>Эксперименты</p>
      </NavbarBrand>
      <NavbarContent className='hidden md:flex gap-4' justify='center'>
        <NavbarItem isActive={location.pathname.includes('task')}>
          <Link
            color='foreground'
            className='cursor-pointer'
            onClick={() => navigate('/task')}
            aria-current='page'>
            Задачи
          </Link>
        </NavbarItem>
        <NavbarItem isActive={location.pathname.includes('experiment')}>
          <Link
            color='foreground'
            className='cursor-pointer'
            onClick={() => navigate('/experiment')}
            aria-current='page'>
            Эксперименты
          </Link>
        </NavbarItem>
        <NavbarItem isActive={location.pathname.includes('practice')}>
          <Link
            color='foreground'
            className='cursor-pointer'
            onClick={() => navigate('/practice')}
            aria-current='page'>
            Опыты
          </Link>
        </NavbarItem>
        <NavbarItem isActive={location.pathname.includes('parameter')}>
          <Link
            color='foreground'
            className='cursor-pointer'
            onClick={() => navigate('/parameter')}
            aria-current='page'>
            Параметры
          </Link>
        </NavbarItem>

        <Divider className='h-8' orientation='vertical' />
        <Dropdown aria-label='custom' key='dropwond_1'>
          <NavbarItem>
            <DropdownTrigger>
              <Button
                disableRipple
                className='p-0 bg-transparent data-[hover=true]:bg-transparent'
                radius='sm'
                variant='light'>
                <NavbarItem className=''>
                  <div className='text-[#11181C]'>Справочники</div>
                </NavbarItem>
              </Button>
            </DropdownTrigger>
          </NavbarItem>
          <DropdownMenu
            className='w-[340px]'
            itemClasses={{
              base: 'gap-4',
            }}>
            <DropdownItem key='measure'>
              <NavbarItem isActive={location.pathname.includes('measure')}>
                <Link
                  color='foreground'
                  className='cursor-pointer'
                  onClick={() => navigate('/measure')}
                  aria-current='page'>
                  Величины
                </Link>
              </NavbarItem>
            </DropdownItem>
            <DropdownItem key='typeCulture'>
              <NavbarItem isActive={location.pathname.includes('typeCulture')}>
                <Link
                  color='foreground'
                  className='cursor-pointer w-full'
                  onClick={() => navigate('/typeCulture')}
                  aria-current='page'>
                  Типы культур
                </Link>
              </NavbarItem>
            </DropdownItem>

            <DropdownItem key='culture'>
              <NavbarItem isActive={location.pathname.includes('culture')}>
                <Link
                  color='foreground'
                  className='cursor-pointer w-full'
                  onClick={() => navigate('/culture')}
                  aria-current='page'>
                  Культуры
                </Link>
              </NavbarItem>
            </DropdownItem>

            <DropdownItem key='status'>
              <NavbarItem isActive={location.pathname.includes('status')}>
                <Link
                  color='foreground'
                  className='cursor-pointer w-full'
                  onClick={() => navigate('/status')}
                  aria-current='page'>
                  Статусы
                </Link>
              </NavbarItem>
            </DropdownItem>
            <DropdownItem key='ground'>
              <NavbarItem isActive={location.pathname.includes('ground')}>
                <Link
                  color='foreground'
                  className='cursor-pointer w-full'
                  onClick={() => navigate('/ground')}
                  aria-current='page'>
                  Земля
                </Link>
              </NavbarItem>
            </DropdownItem>
            <DropdownItem key='weather'>
              <NavbarItem isActive={location.pathname.includes('weather')}>
                <Link
                  color='foreground'
                  className='cursor-pointer w-full'
                  onClick={() => navigate('/weather')}
                  aria-current='page'>
                  Условия
                </Link>
              </NavbarItem>
            </DropdownItem>
            <DropdownItem key='mixture'>
              <NavbarItem isActive={location.pathname.includes('mixture')}>
                <Link
                  color='foreground'
                  className='cursor-pointer w-full'
                  onClick={() => navigate('/mixture')}
                  aria-current='page'>
                  Препараты
                </Link>
              </NavbarItem>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </NavbarContent>

      <NavbarContent justify='end'>
        <NavbarItem className=' hidden md:flex lg:flex'>
          <div className='flex items-center flex-wrap w-16 gap-0.5'>
            <div className='text-xs'> {userStore.schema.lastName} </div>
            <div className='text-xs'> {userStore.schema.firstName}</div>
          </div>
        </NavbarItem>
        <NavbarItem className='lg:flex'>
          <Dropdown placement='bottom-end'>
            <DropdownTrigger>
              <Avatar
                isBordered
                color='secondary'
                as='button'
                className='transition-transform'
                src={avatarUrl}
              />
            </DropdownTrigger>
            <DropdownMenu aria-label='Profile Actions' variant='flat'>
              <DropdownSection aria-label='Profile & Actions' showDivider>
                <DropdownItem isReadOnly key='profile' className='h-14 gap-2'>
                  <User
                    name={userStore.schema.lastName + ' ' + userStore.schema.firstName}
                    description={`@${userStore.schema.login}`}
                    classNames={{
                      name: 'text-default-600',
                      description: 'text-default-500',
                    }}
                    avatarProps={{
                      size: 'sm',
                      src: avatarUrl,
                    }}
                  />
                </DropdownItem>

                <DropdownItem key='settings' onClick={() => navigate('/profile')}>
                  Профиль
                </DropdownItem>
              </DropdownSection>

              <DropdownSection aria-label='Preferences' showDivider>
                <DropdownItem
                  key='calendar'
                  onClick={() => navigate('/calendar')}
                  endContent={<CalendarTwoTone />}>
                  Календарь задач
                </DropdownItem>
                <DropdownItem
                  key='quick_search'
                  onClick={() => {
                    if (userStore.schema.roles.some((el: any) => el.code === listOfRoles.Guest)) {
                      return message.warning('Недостаточно прав,обратитесь к администратору');
                    }
                    message.warning('Загрузка началась');
                    ApiDiContainer.ExelEntityController.getStatistic()
                      .then((response) => {
                        message.success('Файл успешно сформировался');
                        if (!response.ok) {
                          throw new Error('Network response was not ok');
                        }
                        return response.blob();
                      })
                      .then((blob) => {
                        FileSaver.saveAs(blob, 'test_fill.xlsx');
                      })
                      .catch((error) => {
                        message.success('Ошибка при формировании');
                        console.error('There has been a problem with your fetch operation:', error);
                      });
                  }}
                  endContent={<FundTwoTone twoToneColor='#52c41a' />}>
                  Аналитика <br></br> загруженности
                </DropdownItem>
              </DropdownSection>

              <DropdownItem
                key='logout'
                color='danger'
                onClick={() => keycloak.logout()}
                className='text-danger'>
                Выйти
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
};
export default observer(HeaderApp);
