import RestClient from '../restClient';

class СulturePropertyReferenceController {
  static getCulturesType(id: number) {
    return RestClient.getAxios(`/cultures/${id}/type`);
  }
  static getCulturesTypeProperty(id: number, propertyId: number) {
    return RestClient.getAxios(`/cultures/${id}/type/${propertyId}`);
  }
  static deleteCultureType(id: number) {
    return RestClient.deleteAxios(`/cultures/${id}/type`);
  }
  static deleteCultureTypeProperty(id: number, propertyId: number) {
    return RestClient.deleteAxios(`/cultures/${id}/type/${propertyId}`);
  }

  static patchCultureType(id: number, data: any) {
    return RestClient.patchAxios(`/cultures/${id}/type`, data);
  }
}
export default СulturePropertyReferenceController;
