import RestClient from '../restClient';

class TestTypeEntityController {
  static getTestTypes() {
    return RestClient.getAxios(`/test-type`);
  }
  static postTestTypes(data: any) {
    return RestClient.postAxios(`/test-type/search`, data);
  }
  static postTestType(data: any) {
    return RestClient.postAxios(`/test-type`, data);
  }

  static getTestType(id: number) {
    return RestClient.getAxios(`/test-type/${id}`);
  }
  static deleteTestType(id: number) {
    return RestClient.deleteAxios(`/test-type/${id}`);
  }
  static patchTestType(id: number, data: any) {
    return RestClient.patchAxios(`/test-type/${id}`, data);
  }
}
export default TestTypeEntityController;
