import RestClient from '../restClient';

class ExperimentEntityController {
  static postExperiment(data: any) {
    return RestClient.postAxios(`/experiments`, data);
  }
  static postExperiments(data: any) {
    return RestClient.postAxios(`/experiments/search`, data);
  }

  static getExperiment(id: number) {
    return RestClient.getAxios(`/experiments/${id}`);
  }
  static deleteExperiment(id: number) {
    return RestClient.deleteAxios(`/experiments/${id}`);
  }
  static patchExperiment(data: any) {
    return RestClient.patchAxios(`/experiments`, data);
  }
}
export default ExperimentEntityController;
