import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
} from '@nextui-org/react';
// @ts-ignore
import img from 'app/chemistry.svg';

// @ts-ignore;
import avatarUrl from '../../../shared/avatar.png';

// @ts-ignore;
import img2 from '../../../shared/logo.svg';
const SvgLoader = () => {
  return (
    <>
      <div
        style={{
          background: ` no-repeat  right -100px bottom -160px url(${img})`,
          backgroundColor: '#f5faff',
          filter: 'blur(2px)',
          height: '100vh',
        }}>
        <Navbar
          isBordered
          isMenuOpen={false}
          classNames={{
            item: [
              'flex',
              'relative',
              'h-full',
              'items-center',
              "data-[active=true]:after:content-['']",
              'data-[active=true]:after:absolute',
              'data-[active=true]:after:bottom-0',
              'data-[active=true]:after:left-0',
              'data-[active=true]:after:right-0',
              'data-[active=true]:after:h-[2px]',
              'data-[active=true]:after:rounded-[2px]',
              'data-[active=true]:after:bg-primary',
            ],
          }}>
          <NavbarContent className='md:hidden' justify='start'>
            <NavbarMenuToggle aria-label={false ? 'Close menu' : 'Open menu'} />
          </NavbarContent>

          <NavbarBrand className='cursor-pointer'>
            <img src={img2} alt='logo' />
            <p className='font-bold text-inherit textLogo  '>Эксперименты</p>
          </NavbarBrand>
          <NavbarContent className='hidden md:flex gap-4' justify='center'>
            <NavbarItem>
              <Link color='foreground' className='cursor-pointer' aria-current='page'>
                Задачи
              </Link>
            </NavbarItem>
            <NavbarItem>
              <Link color='foreground' className='cursor-pointer' aria-current='page'>
                Эксперименты
              </Link>
            </NavbarItem>
            <NavbarItem>
              <Link color='foreground' className='cursor-pointer' aria-current='page'>
                Опыты
              </Link>
            </NavbarItem>
            <NavbarItem>
              <Link color='foreground' className='cursor-pointer' aria-current='page'>
                Параметры
              </Link>
            </NavbarItem>

            <Divider className='h-8' orientation='vertical' />
            <Dropdown aria-label='custom' key='dropwond_1'>
              <NavbarItem>
                <DropdownTrigger>
                  <Button
                    disableRipple
                    className='p-0 bg-transparent data-[hover=true]:bg-transparent'
                    radius='sm'
                    variant='light'>
                    <NavbarItem className=''>
                      <div className='text-[#11181C]'>Справочники</div>
                    </NavbarItem>
                  </Button>
                </DropdownTrigger>
              </NavbarItem>
              <DropdownMenu
                className='w-[340px]'
                itemClasses={{
                  base: 'gap-4',
                }}>
                <DropdownItem key='measure'>
                  <NavbarItem>
                    <Link color='foreground' className='cursor-pointer' aria-current='page'>
                      Величины
                    </Link>
                  </NavbarItem>
                </DropdownItem>
                <DropdownItem key='typeCulture'>
                  <NavbarItem>
                    <Link color='foreground' className='cursor-pointer w-full' aria-current='page'>
                      Типы культур
                    </Link>
                  </NavbarItem>
                </DropdownItem>

                <DropdownItem key='culture'>
                  <NavbarItem>
                    <Link color='foreground' className='cursor-pointer w-full' aria-current='page'>
                      Культуры
                    </Link>
                  </NavbarItem>
                </DropdownItem>

                <DropdownItem key='status'>
                  <NavbarItem>
                    <Link color='foreground' className='cursor-pointer w-full' aria-current='page'>
                      Статусы
                    </Link>
                  </NavbarItem>
                </DropdownItem>
                <DropdownItem key='ground'>
                  <NavbarItem>
                    <Link color='foreground' className='cursor-pointer w-full' aria-current='page'>
                      Земля
                    </Link>
                  </NavbarItem>
                </DropdownItem>
                <DropdownItem key='weather'>
                  <NavbarItem>
                    <Link color='foreground' className='cursor-pointer w-full' aria-current='page'>
                      Условия
                    </Link>
                  </NavbarItem>
                </DropdownItem>
                <DropdownItem key='mixture'>
                  <NavbarItem>
                    <Link color='foreground' className='cursor-pointer w-full' aria-current='page'>
                      Препараты
                    </Link>
                  </NavbarItem>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarContent>

          <NavbarContent justify='end'>
            <NavbarItem className=' hidden md:flex lg:flex'>
              <div className='flex items-center flex-wrap w-16 gap-0.5'></div>
            </NavbarItem>
            <NavbarItem className='lg:flex'>
              <Dropdown placement='bottom-end'>
                <DropdownTrigger>
                  <Avatar
                    isBordered
                    color='secondary'
                    as='button'
                    className='transition-transform'
                    src={avatarUrl}
                  />
                </DropdownTrigger>
                <DropdownMenu aria-label='Profile Actions' variant='flat'>
                  <DropdownSection aria-label='Profile & Actions' showDivider>
                    <DropdownItem key='settings'>Профиль</DropdownItem>
                  </DropdownSection>

                  <DropdownSection aria-label='Preferences' showDivider>
                    <DropdownItem key='calendar'>Календарь задач</DropdownItem>
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
            </NavbarItem>
          </NavbarContent>
        </Navbar>
      </div>
      <div className='backfront elementToFadeInAndOut'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='600'
          height='600'
          viewBox='0 0 1000 1000'
          fill='none'>
          <rect width='1000' height='1000' fill='none' />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M438 129H562.299V305.787H562.208V315.787H562.299H572.299V305.787V129V119H562.299H438H428V129V305.787V315.787H438H438.091V305.787H438V129Z'
            fill='#194f8d'
          />
          <rect x='396' y='119' width='206' height='10' fill='#194f8d' />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M438 309.796C357.892 335.891 300 411.185 300 500C300 610.457 389.543 700 500 700C610.457 700 700 610.457 700 500C700 411.185 642.108 335.891 562 309.796V320.346C636.491 346.049 690 416.775 690 500C690 604.934 604.934 690 500 690C395.066 690 310 604.934 310 500C310 416.775 363.509 346.049 438 320.346V309.796Z'
            fill='#194f8d'
          />
        </svg>
      </div>
      <div className='progress-9 elementToFadeInAndOut font-bold text-inherit'>загрузка</div>
    </>
  );
};

export default SvgLoader;
