import RestClient from '../restClient';

class GroundEntityController {
  static postGrounds(data: any) {
    return RestClient.postAxios(`/grounds/search`, data);
  }
  static postGround(data: any) {
    return RestClient.postAxios(`/grounds`, data);
  }

  static getGround(id: number) {
    return RestClient.getAxios(`/grounds/${id}`);
  }
  static deleteGround(id: number) {
    return RestClient.deleteAxios(`/grounds/${id}`);
  }
  static patchGround(id: number, data: any) {
    return RestClient.patchAxios(`/ground/${id}`, data);
  }
}
export default GroundEntityController;
