import taskStore from 'entities/task/TaskStore';
import userStore from 'entities/user/UserStore';
import ApiDiContainer from 'shared/apiService/apiDiContainer';

export const listOfRoles = {
  Guest: 'Guest',
  User: 'User',
  Admin: 'Admin',
  Curator: 'Admin',
  TaskDel: 'TaskDel',
};

export const generateDisabledList = (status: string) => {
  console.log(`statys`, status);
  switch (status) {
    case 'В работе':
      return [
        // 'title',
        'testType',
        'parameters',
        'ground',
        'status',
        'cultures',
        'additionalParameters',
        'experiments',
        'numRepeats',
        'place',
        'dateBegin',
        'dateEnd',
      ];

    case 'Запланировано':
      return [
        // 'title',
        'testType',
        'parameters',
        'ground',
        'status',
        'cultures',
        'additionalParameters',
        'experiments',
        'numRepeats',
        'place',
        // 'dateBegin',
        // 'dateEnd',
      ];
    case 'Сделано':
      return [
        'title',
        'testType',
        'parameters',
        'ground',
        userStore.schema.roles.some((el: any) => el.code === listOfRoles.Admin) ? '' : 'status',
        'cultures',
        'additionalParameters',
        'experiments',
        'numRepeats',
        'place',
        'dateBegin',
        'dateEnd',
        'tests',
        'addFile',
      ];
    case 'Черновик':
      return ['status'];
    default:
      return [
        // 'title',
        'testType',
        'parameters',
        'ground',
        'status',
        'cultures',
        'additionalParameters',
        'experiments',
        'numRepeats',
        'place',
        'dateBegin',
        'dateEnd',
      ];
  }
};

export const saveTaskWithStatusLogic = (status: string) => {
  if (status === 'Черновик') {
    return ApiDiContainer.TaskEntityController.postTask({
      ...taskStore.schema,
      // additionalParameters: taskStore.schema.additionalParameters.map((el: any) => {
      //   return {
      //     ...el.parameter,
      //   };
      // }),
      tests: taskStore.generateNum(),
      status: {
        id: 3,
        name: 'Запланировано',
      },
    });
  } else if ((status === 'Запланировано' || status === 'В работе') && taskStore.schema.conclusion) {
    return ApiDiContainer.TaskEntityController.postTask({
      ...taskStore.schema,
      status: {
        id: 1,
        name: 'Сделано',
      },
      tests: taskStore.schema.tests.map((el: any) => {
        return {
          ...el,
          id: +el.id,
        };
      }),
    });
  } else if (status === 'Запланировано') {
    return ApiDiContainer.TaskEntityController.postTask({
      ...taskStore.schema,
      status: {
        id: 2,
        name: 'В работе',
      },
      tests: taskStore.schema.tests.map((el: any) => {
        return {
          ...el,
          id: +el.id,
        };
      }),
    });
  } else {
    return ApiDiContainer.TaskEntityController.postTask({
      ...taskStore.schema,

      tests: taskStore.schema.tests.map((el: any) => {
        return {
          ...el,
          id: +el.id,
        };
      }),
    });
  }
};
