import RestClient from '../restClient';

class TaskEntityController {
  static postTasks(data: any) {
    return RestClient.postAxios(`/tasks/search`, data);
  }
  static postTask(data: any) {
    return RestClient.postAxios(`/tasks`, data);
  }

  static getTask(id: number) {
    return RestClient.getAxios(`/tasks/${id}`);
  }
  static deleteTask(id: number) {
    return RestClient.deleteAxios(`/tasks/${id}`);
  }
  static patchTask(id: number, data: any) {
    return RestClient.patchAxios(`/task/${id}`, data);
  }
}
export default TaskEntityController;
