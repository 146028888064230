import { ReactKeycloakProvider } from '@react-keycloak/web';
import App from 'app';
import keycloak from 'app/providers/providerConfig/Keycloak';
import ReactDOM from 'react-dom/client';
import SvgLoader from 'shared/ui/loader';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ onLoad: 'login-required', pkceMethod: 'S256' }}
    LoadingComponent={<SvgLoader />}
    onTokens={async (token) => {
      await (token.idToken && sessionStorage.setItem('token', token.idToken));
      await (token.token && sessionStorage.setItem('tokenSap', token.token));
    }}

    // onEvent={(event, error) => sessionStorage.setItem('token', keycloak.idToken)}
  >
    <App />
  </ReactKeycloakProvider>,
);
