import RestClient from '../restClient';

class TestResultEntityController {
  static postTestResult(data: any) {
    return RestClient.postAxios(`/test-results`, data);
  }
  static postTestResultList(data: any) {
    return RestClient.postAxios(`/test-results/test-results-list`, data);
  }
  // static postTests(data: any) {
  //   return RestClient.postAxios(`/tests/search`, data);
  // }

  // static getTest(id: number) {
  //   return RestClient.getAxios(`/tests/${id}`);
  // }
  // static deleteTest(id: number) {
  //   return RestClient.deleteAxios(`/tests/${id}`);
  // }
  // static patchTest(id: number, data: any) {
  //   return RestClient.patchAxios(`/tests/${id}`, data);
  // }
}
export default TestResultEntityController;
