import RestClient from '../restClient';

class ParameterEntityController {
  static postParameters(data: any) {
    return RestClient.postAxios(`/parameters/search`, data);
  }
  static postParameter(data: any) {
    return RestClient.postAxios(`/parameters`, data);
  }

  static getParameter(id: number) {
    return RestClient.getAxios(`/parameters/${id}`);
  }
  static deleteParameter(id: number) {
    return RestClient.deleteAxios(`/parameters/${id}`);
  }
  static patchParameter(id: number, data: any) {
    return RestClient.patchAxios(`/parameter/${id}`, data);
  }
}
export default ParameterEntityController;
