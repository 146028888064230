import RestClient from '../restClient';

class TaskStatusEntityController {
  static postTaskStatuses(data: any) {
    return RestClient.postAxios(`/task-statuses/search`, data);
  }
  static postTaskStatus(data: any) {
    return RestClient.postAxios(`/task-statuses`, data);
  }

  static getTaskStatus(id: number) {
    return RestClient.getAxios(`/task-statuses/${id}`);
  }
  static deleteTaskStatus(id: number) {
    return RestClient.deleteAxios(`/task-statuses/${id}`);
  }
  static patchTaskStatus(id: number, data: any) {
    return RestClient.patchAxios(`/task-statuses/${id}`, data);
  }
}
export default TaskStatusEntityController;
