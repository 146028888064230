import RestClient, { makeUrls } from '../restClient';

class ExelEntityController {
  static generateTemplate(data: any) {
    return RestClient.postAxios(`/excel/generate-template`, data);
  }

  static getImage(id: number) {
    return RestClient.getBlob(`/images/download?testId=${id}`);
  }

  static test(data: any) {
    console.log(`data`, data);
    return fetch(makeUrls() + '/excel/generate-template', {
      headers: {
        authorization: 'Bearer ' + sessionStorage.getItem('token'),
        accept: '*/*',
        'accept-language': 'ru,en;q=0.9,la;q=0.8',
        'content-type': 'application/json',
        cookie: 'jenkins-timestamper-offset=-10800000',

        'Referrer-Policy': 'strict-origin-when-cross-origin',
      },
      body: JSON.stringify(data),
      method: 'POST',
    });
  }

  static upload(data: any, id: number) {
    console.log('data is', data);
    return fetch(makeUrls() + `/excel/fill-template?taskId=${id}`, {
      headers: {
        authorization: 'Bearer ' + sessionStorage.getItem('token'),
        accept: '*/*',
        'accept-language': 'ru,en;q=0.9,la;q=0.8',
        'content-type': 'multipart/form-data',
        cookie: 'jenkins-timestamper-offset=-10800000',

        'Referrer-Policy': 'strict-origin-when-cross-origin',
      },
      body: data,
      method: 'POST',
    });
  }

  static postExel(data: any, id: number) {
    return RestClient.postAxiosBlob(`/excel/fill-template?taskId=${id}`, data);
  }

  static getStatistic() {
    return RestClient.getBlob(`/excel/generate-workload-statistic`);
  }
}

export default ExelEntityController;
