import RestClient from '../restClient';

class WidgetEntityController {
  static getWorkLoadWidget() {
    return RestClient.getAxios(`/widget/get-workload-widget`);
  }
  static getNewsfeedWidgetStartDate() {
    return RestClient.getAxios(`/widget/get-newsfeed-widget-start-date`);
  }
  static getNewsfeedWidgetEndDate() {
    return RestClient.getAxios(`/widget/get-newsfeed-widget-end-date`);
  }

  static getCalendar(month: number, year: number) {
    return RestClient.getAxios(`/widget/get-calendar-widget?month=${month}&year=${year}`);
  }
}
export default WidgetEntityController;
