import CultureEntityController from './api/CultureEntityController';
import CultureTypeEntityController from './api/CultureTypeEntityController';
import ExelEntityController from './api/ExelEntityController';
import ExperimentEntityController from './api/ExperimentEntityController';
import GroundEntityController from './api/GroundEntityController';
import ImageEntityController from './api/ImageEntityController';
import MeasureUnitEntityController from './api/MeasureUnitEntityController';
import MixturesEntityController from './api/MixturesEntityController';
import ParameterEntityController from './api/ParameterEntityController';
import TaskEntityController from './api/TaskEntityController';
import TaskStatusEntityController from './api/TaskStatusEntityController';
import TestEntityController from './api/TestEntityController';
import TestResultEntityController from './api/TestResultEntityController';
import TestTypeEntityController from './api/TestTypeEntityController';
import UserEntityConytoller from './api/UserEntityConytoller';
import WeatherEntityController from './api/WeatherEntityController';
import WidgetEntityController from './api/WidgetEntityController';
import СulturePropertyReferenceController from './api/СulturePropertyReferenceController';
class ApiDiContainer {
  static CultureEntityController = CultureEntityController;
  static CultureTypeEntityController = CultureTypeEntityController;
  static СulturePropertyReferenceController = СulturePropertyReferenceController;
  static ExperimentEntityController = ExperimentEntityController;
  static TestEntityController = TestEntityController;
  static MeasureUnitEntityController = MeasureUnitEntityController;
  static ParameterEntityController = ParameterEntityController;
  static TaskEntityController = TaskEntityController;
  static GroundEntityController = GroundEntityController;
  static TaskStatusEntityController = TaskStatusEntityController;
  static TestResultEntityController = TestResultEntityController;
  static ImageEntityController = ImageEntityController;
  static UserEntityConytoller = UserEntityConytoller;
  static ExelEntityController = ExelEntityController;
  static WeatherEntityController = WeatherEntityController;
  static TestTypeEntityController = TestTypeEntityController;
  static WidgetEntityController = WidgetEntityController;
  static MixturesEntityController = MixturesEntityController;
}
export default ApiDiContainer;
