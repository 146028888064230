import { Routing } from '../pages/index';
// import { ReactComponent as YourSvg } from './app/chemistry.svg';
// @ts-ignore
import img from 'app/chemistry.svg';
// import './index.scss';
import * as Sentry from '@sentry/react';
import userStore from 'entities/user/UserStore';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import ApiDiContainer from 'shared/apiService/apiDiContainer';
import HeaderApp from 'shared/ui/header';
import { withProviders } from './providers';
import './styles/index.css';

const App = () => {
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await ApiDiContainer.UserEntityConytoller.getCurrentUser();
        userStore.setVariables(data);
        Sentry.setUser({
          id: data.id,
          username: data.firstName,
          email: data.email,
          // ... и любые другие пользовательские поля
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUser();
  }, []);

  return (
    <div
      className='h-full'
      style={{
        minHeight: '100vh',
        background: ` no-repeat  right -100px bottom -160px url(${img})`,
        backgroundColor: '#f5faff',
      }}>
      <HeaderApp />

      <div className='p-10'>
        <Routing user={userStore.schema} />
      </div>
    </div>
  );
};

export default observer(withProviders(App));
