import RestClient from '../restClient';

class MeasureUnitEntityController {
  static postMeasureUnits(data: any) {
    return RestClient.postAxios(`/measure-units/search`, data);
  }
  static postMeasureUnit(data: any) {
    return RestClient.postAxios(`/measure-units`, data);
  }

  static getMeasureUnit(id: number) {
    return RestClient.getAxios(`/measure-units/${id}`);
  }
  static deleteMeasureUnit(id: number) {
    return RestClient.deleteAxios(`/measure-units/${id}`);
  }
  static patchMeasureUnit(id: number, data: any) {
    return RestClient.patchAxios(`/measureUnits/${id}`, data);
  }
}
export default MeasureUnitEntityController;
