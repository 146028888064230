import { makeAutoObservable } from 'mobx';
import ApiDiContainer from 'shared/apiService/apiDiContainer';

class UserStore {
  schema: any = {
    id: '',
    name: '',
    parameters: [],
    roles: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  update(key: string, val: string | object) {
    this.schema[key] = val;
  }

  setVariables(val: any) {
    this.schema = val;
  }

  resetVariables() {
    this.schema = {
      id: '',
      name: '',
      parameters: [],
      roles: [],
    };
  }

  async getCurrentUser() {
    const res = await ApiDiContainer.UserEntityConytoller.getCurrentUser();
    this.setVariables(await res.data);
  }
}

const userStore = new UserStore();
export default userStore;
