// import { notification } from 'antd';
import axios from 'axios';
import axiosRetry from 'axios-retry';
// window.externalConfigChecked = false; // flag for externalized config check
// window.externalConfigAlerted = false;
axiosRetry(axios, {
  retries: 5,
});
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://188.120.235.15:9501';
export function makeUrls() {
  return BACKEND_URL;
}

class RestClient {
  static getAxios(url: string, additionalHeaders = {}) {
    const config = {
      method: 'get',
      url: makeUrls() + url,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        ...additionalHeaders,
      },
    };
    return axios(config);
  }
  static getAxiosBlob(url: string, additionalHeaders = {}) {
    const config = {
      method: 'get',
      reponseType: 'blob',
      url: makeUrls() + url,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        Accept: ' */*',

        ...additionalHeaders,
      },
    };
    return axios(config);
  }
  static getBlob(url: string, additionalHeaders = {}) {
    return fetch(makeUrls() + url, {
      headers: {
        accept: '*/*',
        'accept-language': 'ru,en;q=0.9,la;q=0.8',
        authorization: 'Bearer ' + sessionStorage.getItem('token'),
        Referer: makeUrls() + '/swagger-ui/index.html',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
      },
      body: null,
      method: 'GET',
    });
  }
  static getBlobSap(url: string, additionalHeaders = {}) {
    return fetch(url, {
      headers: {
        accept: '*/*',
        'accept-language': 'ru,en;q=0.9,la;q=0.8',
        authorization: 'Bearer ' + sessionStorage.getItem('tokenSap'),
        Referer: makeUrls() + '/swagger-ui/index.html',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
      },
      body: null,
      method: 'GET',
    });
  }
  static postAxios(url: string, data: object, additionalHeaders = {}) {
    const config = {
      method: 'post',
      url: makeUrls() + url,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        ...additionalHeaders,
      },
      data: data,
    };
    return axios(config);
  }

  static postAxiosBlob(url: string, data: any, additionalHeaders = {}) {
    const config = {
      method: 'post',

      url: makeUrls() + url,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': `multipart/form-data`,
        ...additionalHeaders,
      },
      data: data,
    };
    return axios(config);
  }

  static patchAxios(url: string, data: object) {
    const config = {
      method: 'patch',
      url: makeUrls() + url,

      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
      data: data,
    };
    return axios(config);
  }

  static async deleteAxios(url: string) {
    const config = {
      method: 'delete',
      url: makeUrls() + url,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
    };
    return axios(config);
  }
}

export default RestClient;
